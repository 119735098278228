.logo {
  width: 175px;
  /*background: rgba(255, 255, 255, 0.2);*/
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.logo img {
  height: 45px;
  width: 175px;
}

.site-layout .site-layout-background {
  /*background: #fff;*/
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}
